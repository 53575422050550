/*------------------------------------------------------------
  Site Style
------------------------------------------------------------*/

.site-header{
    
    text-align: center;    

    .wrapper{
        
        padding-top: 5em;
        padding-bottom: 2em;
        
        .site-title{
            text-decoration: none;
            color: $text-color-white;
            font-size: $big-font-size;
            font-weight: bold;
            text-transform: uppercase;
        }
    }
    
    
    .header-badge{
        border-radius: 50%;
        border: 12px #fff solid;
        height: 200px;
        width: 200px;
        background: url('../assets/home.svg') center center no-repeat #fff;
        background-size: 50px 50px;
        display: inline-block;
        margin-bottom: -140px;        
        overflow: hidden;
        transition: $anim-speed; 
        @extend .z-depth-1;
        
        
        img{
            object-fit: none; /* Do not scale the image */
            object-position: center; /* Center the image within the element */
            height: 176px;
            width: 176px;
            border-radius: 50%;
            opacity: 1;
            transition: $anim-speed;        
        }        
    }
    
    .header-badge:hover{        
        transition: $anim-speed;   
        @extend .z-depth-5;
        
        img{         
            opacity: 0.7;
            transition: $anim-speed;  
        }
        
    }
}

.page-content{ 
    margin: $spacing-unit auto;   
    width: 980px;    
    
    .top{
        
        text-align: center;
        padding: $spacing-unit * 4 $spacing-unit $spacing-unit;       
        
        a.email{
            font-size: 2rem;
            text-decoration: none;
            color: $brand-color;
        }

        p.description{            
            font-size: 2rem;
            text-align: left;
        }
    }
    
    .posts{
        .card-title{
            color: $brand-color;
            display: block;
            width: 100%;
            border-bottom: $brand-color solid 1px;
        }
        
        .post-list{
            margin:0;
            padding:0;
            
            li{
                
                padding: 15px 0;
                border-bottom: #ddd solid 1px;
                
                .post-meta{
                    float:right;
                    color:#ccc;
                    font-size: 14px;
                }
                
                a{
                   
                    text-decoration: none;
                    color:#333;
                    font-size: 21px;
                    
                }
            }
        }
        
        .rss-subscribe{
            text-align: center;
            margin-top: 15px;
            
            a{
                text-decoration: none;
                color:$brand-color;
            }
        }
    }
}

.post{
    .card-content{
            padding: 80px 20px;
    }
}

.footer-heading{
    display:block;
    text-align: center;
    color: #fff;
    font-size: 2.6rem;
    padding-bottom: 0.5em;
    font-weight: 300;
}

.footer-col-wrapper {
    background: #ffffff;
}

.footer-col {
    display: block;
    width: 33%;
    float: left;
    padding: 2px;
    margin: 0px;
    height: 100%;
    background: #ffffff;
    text-align: center;
}

@media (max-width: 1050px) {
    .page-content{  
        margin: $spacing-unit 2%;
        width: 96%;
    }
}

.site-footer {
    margin: 30px auto;
    width: 980px;
}
